
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { Pageniation as Table } from "@/utils/decorator";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";

const app = namespace("app");
const base = namespace("base");
const learning = namespace("learning");
@Component({
  components: { FileUpload, Editor }
})
@Table("loadListData")
export default class LearningView extends Vue {
  @base.Action getDictionaryList;
  @app.Mutation setPBPX;
  @app.State btnLoading;
  // 学习
  @learning.Action queryLearnClassList;
  @learning.Action getLearnContentSettingDetail; //获取学习内容设置明细
  @learning.Action getLearnContentTextDetail; //获取学习内容文本明细
  @learning.Action releaseLearnContent;
  @learning.Action offShelfLearnContent;

  emptyToLine = emptyToLine;
  contentForm: any = {
    learnContentTitle: "", //标题
    learnContentType: "", //分类
    scopeType: "storeType", //发布范围类型
    scopeCodeList: [], //发布范围
    signature: "", //署名
    bannerUrl: "", //小图
    connectedExternal: "false", //链接至第三方
    admissionExamination: "false", //允许报名考试
    examinationCode: "", //关联考试
    signUpDeadlineTime: "", //报名截止日期
    externalUrl: "", //地址
    learnContentAbstract: "", //摘要
    learnContentText: "", //学习内容
    annexList: [], //添加附件
    isRelease: "" //是否发布，false-否，true-是; 默认 false
  };
  studyStatus: any = {
    unpublished: t("v210831.unpublished"),
    published: t("v210831.published"),
    offShelf: t("v210831.removed")
  };
  contentRangeList: any[] = [];
  classSearchValue: string = "";
  examName: string = "";
  checkRecordVisible: boolean = false;
  dayjs = dayjs;
  checkFrontImage(value, callback) {
    if (this.contentForm.bannerType === "default") {
      callback();
    } else {
      if (!value) {
        callback(new Error(t("v210801.please-upload-pictures")));
      } else {
        callback();
      }
    }
  }
  get breadData() {
    return [
      { name: t("v210831.learning-content-management"), path: "/learning/learning-list" },
      {
        name: t("v210831.learning-details")
      }
    ];
  }
  get isAdd() {
    return this.$route.name === "learningAdd";
  }
  created() {
    this.setPBPX(52);
    this.init();
  }
  get id() {
    return this.$route.params.id;
  }
  defaultBannerList: any = [];
  init() {
    this.getContentDetail();
  }
  mounted() {}
  handlePreview(file) {
    window.location.href = file.url;
  }
  //   获取详情
  getContentDetail() {
    this.getLearnContentSettingDetail({ learnContentCode: this.id }).then(data => {
      this.contentForm = data.data;
      this.examName = this.contentForm.examinationCodeDesc;
      this.queryLearnClassList({
        pageNum: 1,
        pageSize: 999
      }).then(data => {
        data.data.list.forEach(v => {
          if (v.classCode == this.contentForm.learnContentType) {
            this.classSearchValue = v.className;
          }
        });
      });
      this.contentForm.annexList = this.contentForm.annexList.map(v => {
        return { ...v, name: v.annexName, url: v.annexUrl };
      });
      let list = [];
      let rangeType = this.contentForm.scopeType === "storeType" ? "store_type" : "staff_level";
      this.getDictionaryList(rangeType).then(data => {
        data.forEach(k => {
          if (this.contentForm.scopeCodeList.includes(k.value)) {
            list.push(k.label);
          }
        });
        this.contentForm.scopeCodeList = list;
      });
    });
    // this.getLearnContentTextDetail({ learnContentCode: this.id }).then(data => {
    //   this.contentForm = data.data;
    //   this.classSearchValue = this.contentForm.learnContentTypeDesc;
    // });
  }
  //   编辑
  handleEdit() {
    this.$router.push("/learning/learning-edit/" + this.id);
  }
  //   发布
  handleRelease() {
    this.releaseLearnContent({ learnContentCode: this.id }).then(() => {
      this.$message.success(t("v210831.published-successfully"));
      this.getContentDetail();
    });
  }
  //   下架
  handleWithdraw() {
    this.offShelfLearnContent({ learnContentCode: this.id }).then(() => {
      this.$message.success(t("v210831.removed-successfully"));
      this.getContentDetail();
    });
  }
  //   查看操作记录
  checkRecord() {
    this.checkRecordVisible = true;
  }
}
